import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Amplify, Auth, Hub } from 'aws-amplify';
import awsConfig from './aws-exports';
 
import Layout from "./components/Layout/Layout";
import Cars from "./pages/Cars/Cars";
import PastEntries from "./pages/Home/Home";
import MyTotals from './pages/MyTotals/MyTotals';
import NotFound from "./pages/NotFound/NotFound";
import AdminView from './pages/Admin/AdminView';
import SignIn from './pages/SignIn/SignIn';
import Expense from './pages/Expense/Expense';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const isProduction = Boolean(window.location.hostname.includes('constantadvancement'));


// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const isConstantAdvancement = Boolean(window.location.hostname.includes('constantadvancement'));
const isDevAppMigration = Boolean(window.location.hostname.includes('dev-app-migration'));

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  appMigrationSignIn,
  constantAdvancementSignIn,
  devAppMigrationSignIn
] = awsConfig.oauth.redirectSignIn.split(',');

const [
  localRedirectSignOut,
  appMigrationSignOut,
  constantAdvancementSignOut,
  devAppMigrationSignOut
] = awsConfig.oauth.redirectSignOut.split(',');

const productionRedirectSignIn = isConstantAdvancement ? constantAdvancementSignIn : devAppMigrationSignIn;
const productionRedirectSignOut = isConstantAdvancement ? constantAdvancementSignOut : devAppMigrationSignOut;

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);

function App() {
  
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  async function getUser() {
    try {
      const token = await Auth.currentAuthenticatedUser();
      setLoading(false);
      setUser(token);
    } catch(err) {
      console.log(err);
      setLoading(false);
    }
  }

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signIn') {
        return getUser();
      }
      if (payload.event === 'signOut') {
        setUser(null);
        return setLoading(false);
      }
    });

    getUser();
  }, []);

  return(
    
        // <Authenticator hideSignUp={true}>
        //   {({ signOut, user}) => (
        //     <BrowserRouter>
        //     <Routes>
        //       <Route path="/" element={<Layout user={user}/>}>
        //         <Route index element={<PastEntries signOut={signOut} user={user}/>} />
        //         <Route path="cars" element={<Cars signOut={signOut} user={user} />} />
        //         {/* <Route path="pastentries" element={<PastEntries signOut={signOut} user={user} />} /> */}
        //         <Route path="mytotals" element={<MyTotals signOut={signOut} user={user} />} />
        //         <Route path="adminview" element={<AdminView signOut={signOut} user={user} />} />
        //         <Route path="*" element={<NotFound />} />
        //       </Route>
        //     </Routes>
        //   </BrowserRouter>
        //   )}
        // </Authenticator>
        <div>
          {user
            ? <BrowserRouter>
                 <Routes>
                   <Route path="/" element={<Layout user={user}/>}>
                     <Route index element={<PastEntries user={user}/>} />
                     <Route path="cars" element={<Cars user={user} />} />
                     <Route path="expensing" element={<Expense user={user} />} />
                     <Route path="mytotals" element={<MyTotals user={user} />} />
                     <Route path="adminview" element={<AdminView user={user} />} />
                     <Route path="*" element={<NotFound />} />
                   </Route>
                 </Routes>
               </BrowserRouter>
            : <SignIn loading={loading}></SignIn>}
        </div>
     
  );
}

export default App;