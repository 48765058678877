import * as React from "react";
import { useState } from "react";
import {
  Button,
  Flex,
  Grid,
  Radio,
  RadioGroupField,
  SelectField,
  TextField,
} from "@aws-amplify/ui-react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Expense } from "../models";
import { fetchByPath, validateField } from "./utils";
import { Auth, DataStore } from "aws-amplify";


export default function CreateExpenseForm(props) {
  const {
    adminView,
    selectedUser,
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    Date: "",
    Item: "",
    Comments: "",
    Cost: "",
    Mileage: "",
    Total: "",
    Billable: undefined,
    BillableTotal: "",
  };
  const [user, setUser] = React.useState(null);
  const [Date, setDate] = React.useState(initialValues.Date);
  const [Item, setItem] = React.useState(initialValues.Item);
  const [Comments, setComments] = React.useState(initialValues.Comments);
  const [Cost, setCost] = React.useState(initialValues.Cost);
  const [Mileage, setMileage] = React.useState(initialValues.Mileage);
  const [Total, setTotal] = React.useState(initialValues.Total);
  const [Billable, setBillable] = React.useState(initialValues.Billable);
  const [BillableTotal, setBillableTotal] = React.useState(
    initialValues.BillableTotal
  );
  const [file, setFile] = useState();
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setDate(initialValues.Date);
    setItem(initialValues.Item);
    setComments(initialValues.Comments);
    setCost(initialValues.Cost);
    setMileage(initialValues.Mileage);
    setTotal(initialValues.Total);
    setBillable(initialValues.Billable);
    setBillableTotal(initialValues.BillableTotal);
    setErrors({});
  };


  const partnerCats = [
    'Telephone and internet',
    'Partner Commute G&A',
    'Partner Commute Overhead',
    'Gym Membership',
    'Pet Insurance',
    'Leidos Mileage',
    'Travel Unallowable',
    'Travel Billable',
    'Travel Overhead',
    'Travel G&A',
    'Meals & Entertainment- G&A',
    'Meals & Entertainment- Overhead',
    'Meals & Entertainment- Fringe',
    'Meals & Entertainment- Billable',
    'Computer Supplies',
    'Clothing',
  ]

  const employeeCats = [
    'Leidos Mileage',
    ' Meals & Entertainment- Fringe',
    'Meals & Entertainment- Billable',
    'Meals & Entertainment- G&A',
    'Meals & Entertainment- Overhead',
    'Gym Membership',
    'Pet Insurance',
    'Travel-Billable',
    'Travel- Overhead',
    'Travel- G & A',
    'Travel-Fringe',
    'Shipping',
    'Computer Supplies',
    'Training',
    'Unallowable Alcohol',
    'G&A Research',
  ]

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const validations = {
    Date: [{ type: "Required" }],
    Item: [{ type: "Required" }],
    Comments: [],
    Cost: [{ type: "Required" }],
    Mileage: [],
    Total: [],
    Billable: [],
    BillableTotal: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };


  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        setUser(currentUser);
      } catch (error) {
        console.log("Error fetching user:", error);
      }
    };

    fetchUser();
  }, []);


  const categoriesDropDown = () => {
    if (user === null) {
      return null;
    } else if (adminView && ['049884a8-8011-703c-383f-33320983b48f', 'e4a87458-7001-70ae-5c59-0a07437562b4', 'b468f4d8-60e1-70ca-c97f-ae7de2b13ac4'].includes(selectedUser)) {
      return (
        <SelectField
          label="Expense Category:"
          onChange={(e) => {
            const selectedValue = e.target.value;
            this.setState({ selectedCategory: selectedValue === "all" ? null : selectedValue });
          }}
        >
          {partnerCats.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </SelectField>
      );
    } else if (adminView && !['049884a8-8011-703c-383f-33320983b48f', 'e4a87458-7001-70ae-5c59-0a07437562b4', 'b468f4d8-60e1-70ca-c97f-ae7de2b13ac4'].includes(selectedUser)) {
      return (
        <SelectField
          label="Expense Category:"
          onChange={(e) => {
            const selectedValue = e.target.value;
            this.setState({ selectedCategory: selectedValue === "all" ? null : selectedValue });
          }}
        >
          {employeeCats.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </SelectField>
      );
    } else if (['049884a8-8011-703c-383f-33320983b48f', 'e4a87458-7001-70ae-5c59-0a07437562b4', 'b468f4d8-60e1-70ca-c97f-ae7de2b13ac4'].includes(selectedUser)) {
      return (
        <SelectField
          label="Expense Category:"
          onChange={(e) => {
            const selectedValue = e.target.value;
            this.setState({ selectedCategory: selectedValue === "all" ? null : selectedValue });
          }}
        >
          {partnerCats.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </SelectField>
      );
    };
    return (
      <SelectField
        label="Expense Category:"
        onChange={(e) => {
          const selectedValue = e.target.value;
          this.setState({ selectedCategory: selectedValue === "all" ? null : selectedValue });
        }}
      >
        {employeeCats.map((category, index) => (
          <option key={index} value={category}>
            {category}
          </option>
        ))}
      </SelectField>
    );
  }


  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          Date,
          Item,
          Comments,
          Cost,
          Mileage,
          Total,
          Billable,
          BillableTotal,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value.trim() === "") {
              modelFields[key] = undefined;
            }
          });
          await DataStore.save(new Expense(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ExpenseForm")}
      {...rest}
    >
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Date</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        type="date"
        value={Date}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              Date: value,
              Item,
              Comments,
              Cost,
              Mileage,
              Total,
              Billable,
              BillableTotal,
            };
            const result = onChange(modelFields);
            value = result?.Date ?? value;
          }
          if (errors.Date?.hasError) {
            runValidationTasks("Date", value);
          }
          setDate(value);
        }}
        onBlur={() => runValidationTasks("Date", Date)}
        errorMessage={errors.Date?.errorMessage}
        hasError={errors.Date?.hasError}
        {...getOverrideProps(overrides, "Date")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Item</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        descriptiveText="What are you expensing?"
        isRequired={true}
        isReadOnly={false}
        value={Item}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              Date,
              Item: value,
              Comments,
              Cost,
              Mileage,
              Total,
              Billable,
              BillableTotal,
            };
            const result = onChange(modelFields);
            value = result?.Item ?? value;
          }
          if (errors.Item?.hasError) {
            runValidationTasks("Item", value);
          }
          setItem(value);
        }}
        onBlur={() => runValidationTasks("Item", Item)}
        errorMessage={errors.Item?.errorMessage}
        hasError={errors.Item?.hasError}
        {...getOverrideProps(overrides, "Item")}
      ></TextField>
      {categoriesDropDown()}
      {console.log("selected user:")}
      {console.log(selectedUser)}
      <TextField
        label="Comments"
        descriptiveText="Additional expense detail (if needed)"
        isRequired={false}
        isReadOnly={false}
        value={Comments}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              Date,
              Item,
              Comments: value,
              Cost,
              Mileage,
              Total,
              Billable,
              BillableTotal,
            };
            const result = onChange(modelFields);
            value = result?.Comments ?? value;
          }
          if (errors.Comments?.hasError) {
            runValidationTasks("Comments", value);
          }
          setComments(value);
        }}
        onBlur={() => runValidationTasks("Comments", Comments)}
        errorMessage={errors.Comments?.errorMessage}
        hasError={errors.Comments?.hasError}
        {...getOverrideProps(overrides, "Comments")}
      ></TextField>
      <TextField
        label={
          <span style={{ display: "inline-flex" }}>
            <span>Cost</span>
            <span style={{ color: "red" }}>*</span>
          </span>
        }
        isRequired={true}
        isReadOnly={false}
        placeholder="$0.00"
        type="number"
        step="any"
        value={Cost}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              Date,
              Item,
              Comments,
              Cost: value,
              Mileage,
              Total,
              Billable,
              BillableTotal,
            };
            const result = onChange(modelFields);
            value = result?.Cost ?? value;
          }
          if (errors.Cost?.hasError) {
            runValidationTasks("Cost", value);
          }
          setCost(value);
        }}
        onBlur={() => runValidationTasks("Cost", Cost)}
        errorMessage={errors.Cost?.errorMessage}
        hasError={errors.Cost?.hasError}
        {...getOverrideProps(overrides, "Cost")}
      ></TextField>
      <TextField
        label="Mileage"
        isRequired={false}
        isReadOnly={false}
        placeholder="0.0"
        type="number"
        step="any"
        value={Mileage}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              Date,
              Item,
              Comments,
              Cost,
              Mileage: value,
              Total,
              Billable,
              BillableTotal,
            };
            const result = onChange(modelFields);
            value = result?.Mileage ?? value;
          }
          if (errors.Mileage?.hasError) {
            runValidationTasks("Mileage", value);
          }
          setMileage(value);
        }}
        onBlur={() => runValidationTasks("Mileage", Mileage)}
        errorMessage={errors.Mileage?.errorMessage}
        hasError={errors.Mileage?.hasError}
        {...getOverrideProps(overrides, "Mileage")}
      ></TextField>
      {/* <TextField
        label="Total"
        isRequired={false}
        isReadOnly={false}
        placeholder="$0.00"
        type="number"
        step="any"
        value={Total}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              Date,
              Item,
              Comments,
              Cost,
              Mileage,
              Total: value,
              Billable,
              BillableTotal,
            };
            const result = onChange(modelFields);
            value = result?.Total ?? value;
          }
          if (errors.Total?.hasError) {
            runValidationTasks("Total", value);
          }
          setTotal(value);
        }}
        onBlur={() => runValidationTasks("Total", Total)}
        errorMessage={errors.Total?.errorMessage}
        hasError={errors.Total?.hasError}
        {...getOverrideProps(overrides, "Total")}
      ></TextField> */}


      <RadioGroupField
        label="Billable"
        name="Billable"
        isReadOnly={false}
        isRequired={false}
        onChange={(e) => {
          let value = e.target.value === "true";
          if (onChange) {
            const modelFields = {
              Date,
              Item,
              Comments,
              Cost,
              Mileage,
              Total,
              Billable: value,
              BillableTotal,
            };
            const result = onChange(modelFields);
            value = result?.Billable ?? value;
          }
          if (errors.Billable?.hasError) {
            runValidationTasks("Billable", value);
          }
          setBillable(value);
        }}
        onBlur={() => runValidationTasks("Billable", Billable)}
        errorMessage={errors.Billable?.errorMessage}
        hasError={errors.Billable?.hasError}
        {...getOverrideProps(overrides, "Billable")}
      >
        <Radio
          children="Yes"
          value="true"
          {...getOverrideProps(overrides, "BillableRadio0")}
        ></Radio>
        <Radio
          children="No"
          value="false"
          {...getOverrideProps(overrides, "BillableRadio1")}
        ></Radio>
      </RadioGroupField>
      <label>Add Receipt</label>
      <div >
        <input type="file" onChange={handleChange} />
        {/* <img src={file} /> */}
      </div>
      {/* <TextField
        label="Billable total"
        isRequired={false}
        isReadOnly={false}
        placeholder="$0.00"
        type="number"
        step="any"
        value={BillableTotal}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              Date,
              Item,
              Comments,
              Cost,
              Mileage,
              Total,
              Billable,
              BillableTotal: value,
            };
            const result = onChange(modelFields);
            value = result?.BillableTotal ?? value;
          }
          if (errors.BillableTotal?.hasError) {
            runValidationTasks("BillableTotal", value);
          }
          setBillableTotal(value);
        }}
        onBlur={() => runValidationTasks("BillableTotal", BillableTotal)}
        errorMessage={errors.BillableTotal?.errorMessage}
        hasError={errors.BillableTotal?.hasError}
        {...getOverrideProps(overrides, "BillableTotal")}
      ></TextField> */}
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
