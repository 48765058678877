import * as React from "react";
import { CarData, Users } from "../models";
import { SortDirection } from "@aws-amplify/datastore";
import {
  getOverrideProps,
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";
import CustomCarTableEntry from "./CustomCarTableEntry";
import { Collection, SelectField } from "@aws-amplify/ui-react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { UpdateCarForm } from "./UpdateCarForm";
import CustomCarTableHeader from "./CustomCarTableHeader";
import { CarTableHeader } from "../ui-components";
import { dropdown } from "../functions/FilterDropdown";

export default function CustomCarTableEntryCollection(props) {
  const { items: itemsProp, userID, adminView, overrideItems, overrides, ...rest } = props;
  const itemsPagination = { sort: (s) => s.year(SortDirection.ASCENDING) };
  const [items, setItems] = React.useState(undefined);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedUserID, setSelectedUserID] = React.useState();
  const [selectedCarID, setSelectedCarID] = React.useState();
  const [userDropdownList, setUserDropdownList] = React.useState();
  const [selectedFilterUser, setSelectedFilterUser] = React.useState(null);
  const [dropdownValue, setDropdownValue] = React.useState();

  const itemsDataStore = useDataStoreBinding({
    type: "collection",
    model: CarData,
    pagination: itemsPagination,
  }).items;
  const usersDataStore = useDataStoreBinding({
    type: "collection",
    model: Users,
  }).items;

  React.useEffect(() => {
    const dropdownOptions = [{value: "all", label: "All Users"}];
    for (let x in usersDataStore) {
      // remove users with null names from list:
      if (usersDataStore[x].last_name != null && usersDataStore[x].owner  && !usersDataStore[x].owner.includes("google")){
        if (usersDataStore[x].owner == null){
          dropdownOptions.push({ value:usersDataStore[x].id, label: usersDataStore[x].first_name + " " + usersDataStore[x].last_name});
        } else {
          dropdownOptions.push({ value:usersDataStore[x].owner, label: usersDataStore[x].first_name + " " + usersDataStore[x].last_name});
        }  
      }
      
    }
    if(dropdownValue == null && dropdownOptions.some(item => item.value === userID)){
      setSelectedFilterUser(userID);
      setDropdownValue(userID);
    }
    setUserDropdownList(
      <SelectField
        size="small"
        value={dropdownValue}
        labelHidden={true}
        onChange={(e) => {
          const val = e.target.value;
          if (val === "all"){
            setSelectedFilterUser(null);
          } else {
            setSelectedFilterUser(val);
          }
          setDropdownValue(val);
        }}
      >
        {dropdownOptions.map(({value, label}, index) => <option key={value} value={value}>{label}</option>)}
      </SelectField>
    )
  }, [usersDataStore, dropdownValue])

  React.useEffect(() => {
    // if (itemsProp !== undefined) {
    //   setItems(itemsProp);
    //   return;
    // }
    async function setItemsFromDataStore() {
      var loaded = await Promise.all(
        itemsDataStore.map(async (item) => ({
          ...item,
          // CommuteEntries: await item.CommuteEntries.toArray(),
        }))
      );
      if (selectedFilterUser) {
        setItems(loaded.filter(item => item.usersID.includes(selectedFilterUser)));
      } else {
        // filter by all users
        let allUserIds = usersDataStore.map( entry => entry.id);
        setItems(loaded.filter(item => allUserIds.some( userId => item.usersID.includes(userId))))
      }
    }
    setItemsFromDataStore();
  }, [itemsProp, itemsDataStore, selectedFilterUser]);
  const handleClickOpenEdit = (userID, carID) => {
    setSelectedUserID(userID);
    setSelectedCarID(carID);
    setOpenEdit(true);

  };

  const handleClose = () => {
    setOpenEdit(false);
  };

  return (
    <div>

      {dropdown(adminView, "Select User:", userDropdownList)}
      
      <CustomCarTableHeader/>
      <Dialog open={openEdit} onClose={handleClose}>
        <DialogTitle>Edit Car Entry</DialogTitle>
        <DialogContent>
          <UpdateCarForm userID={selectedUserID} carid={selectedCarID} closeDialog={handleClose}></UpdateCarForm>
        </DialogContent>
      </Dialog>
      <Collection
      type="list"
      isPaginated={true}
      searchPlaceholder="Search..."
      direction="column"
      justifyContent="left"
      items={items || []}
      {...getOverrideProps(overrides, "CarTableEntryCollection")}
      {...rest}
    >
      {(item, index) => (
        <CustomCarTableEntry
          carData={item}
          key={item.id}
          openEditDialog={handleClickOpenEdit}
          {...(overrideItems && overrideItems({ item, index }))}
        ></CustomCarTableEntry>
      )}
    </Collection>
    </div>
    
  );
}
