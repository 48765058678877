import * as React from "react";
import { Users } from "../models";
import {
  getOverrideProps,
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";
import CustomTotalsTableEntry from "./CustomTotalsTableEntry";
import { Collection } from "@aws-amplify/ui-react";

export default function CustomTotalsTableEntryCollection(props) {
  const { items: itemsProp, monthFilter, overrideItems, overrides, ...rest } = props;
  const [items, setItems] = React.useState(undefined);
  const [totalRowInfo, setTotalRowInfo] = React.useState([]);
  const [overallGATotal, setOverallGATotal] = React.useState();
  const [overallOverheadTotal, setOverallOverheadTotal] = React.useState();
  const [overallUnallowableTotal, setOverallUnallowableTotal] = React.useState();
  const [overallFinalTotal, setOverallFinalTotal] = React.useState();
  const itemsDataStore = useDataStoreBinding({
    type: "collection",
    model: Users,
  }).items;
  React.useEffect(() => {
    if (itemsProp !== undefined) {
      setItems(itemsProp);
      return;
    }
    async function setItemsFromDataStore() {
      var loaded = await Promise.all(
        itemsDataStore.map(async (item) => ({
          ...item,
          CommuteEntries: await item.CommuteEntries.toArray(),
          CarData: await item.CarData.toArray(),
        }))
      );

      // remove users with null names from list:
      var userList = [];
      for (let x in loaded) {
        if (loaded[x].last_name != null && loaded[x].owner && !loaded[x].owner.includes("google")){
          userList.push(loaded[x]);
        } 
      }
      setItems(userList);
    }
    setItemsFromDataStore();
  }, [itemsProp, itemsDataStore]);

  function appendTotalRow(object){
    var index = totalRowInfo?.findIndex(x => x.user === object.user);
    const temp = [...totalRowInfo];
    if(index < 0){
      temp.push(object);
    } else {
      temp[index] = object;
    }
    setTotalRowInfo(temp);
  }

  React.useEffect(() => {
    if (totalRowInfo == null || totalRowInfo.length === 0){
      setOverallGATotal(0)
      setOverallOverheadTotal(0)
      setOverallUnallowableTotal(0)
      setOverallFinalTotal(0)
    } else {
      let gaResult = totalRowInfo.reduce(function(a, b){
        let totalA = a;
        let totalB = parseFloat(b.ga);
        if (typeof(a) != 'number'){
          totalA = parseFloat(a.ga);
        }
        return totalA + totalB;
      });
      if (typeof(gaResult) == 'number'){
        setOverallGATotal(gaResult.toFixed(2));
      }

      let overheadResult = totalRowInfo.reduce(function(a, b){
        let totalA = a;
        let totalB = parseFloat(b.overhead);
        if (typeof(a) != 'number'){
          totalA = parseFloat(a.overhead);
        }
        return totalA + totalB;
      });
      if (typeof(overheadResult) == 'number'){
        setOverallOverheadTotal(overheadResult.toFixed(2));
      }

      let unallowableResult = totalRowInfo.reduce(function(a, b){
        let totalA = a;
        let totalB = parseFloat(b.unallowable);
        if (typeof(a) != 'number'){
          totalA = parseFloat(a.unallowable);
        }
        return totalA + totalB;
      });
      if (typeof(unallowableResult) == 'number'){
        setOverallUnallowableTotal(unallowableResult.toFixed(2));
      }

      let finalResult = totalRowInfo.reduce(function(a, b){
        let totalA = a;
        let totalB = parseFloat(b.total);
        if (typeof(a) != 'number'){
          totalA = parseFloat(a.total);
        }
        return totalA + totalB;
      });
      if (typeof(finalResult) == 'number'){
        setOverallFinalTotal(finalResult.toFixed(2));
      }
    }
    
  }, [totalRowInfo])

  return (
    <div>
        <Collection
        type="list"
        searchPlaceholder="Search..."
        itemsPerPage={6}
        direction="column"
        justifyContent="left"
        items={items || []}
        {...getOverrideProps(overrides, "TotalsTableEntryCollection")}
        {...rest}
      >
        {(item, index) => (
          <CustomTotalsTableEntry
            users={item}
            key={item.id}
            monthFilter={monthFilter}
            totalRowAppend={appendTotalRow}
            {...(overrideItems && overrideItems({ item, index }))}
          ></CustomTotalsTableEntry>
        )}
      </Collection>
      <CustomTotalsTableEntry
        overrides={{
          name: {children: "Totals"},
          ga: {children: `${"$"}${overallGATotal}`},
          overhead: {children: `${"$"}${overallOverheadTotal}`},
          unallowable: {children: `${"$"}${overallUnallowableTotal}`},
          total: {children: `${"$"}${overallFinalTotal}`}
        }}
      ></CustomTotalsTableEntry>
    </div>
    
  );
}