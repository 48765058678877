import React, { useState } from 'react';
import './AdminView.css';
import { Flex, SelectField, Button } from '@aws-amplify/ui-react';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import Header from '../../components/Header';
import { TotalsHeader } from '../../ui-components';
import UserCarTotalsEntryCollection from '../../components/UserCarTotalsEntryCollection';
import CustomTotalsTableEntryCollection from '../../components/CustomTotalsTableEntryCollection';
import { Mileage, Users } from '../../models';
import { useDataStoreBinding } from "@aws-amplify/ui-react/internal";
import { dropdown } from '../../functions/FilterDropdown';
import CustomMileageCreateForm from '../../components/CustomMileageCreateForm';
import { EmployeeEntry } from '../../ui-components';
import { EmployeeEntryHeader } from '../../ui-components';
import EmployeeEntryCollection from '../../components/EmployeeEntryCollection';
import { checkUserTable } from '../../functions/UserTablePopulate';

function AdminView({ signOut, user}) {
  const [monthFilter, setMonthFilter] = useState(new Date());
  const [userDropdownList, setUserDropdownList] = React.useState();
  const [selectedFilterUser, setSelectedFilterUser] = React.useState();
  const [currentYearMileage, setCurrentYearMileage] = React.useState();
  const [filteredYearMileage, setFilteredYearMileage] = React.useState();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const usersDataStore = useDataStoreBinding({
    type: "collection",
    model: Users,
  }).items;
  const mileageDataStore = useDataStoreBinding({
    type: "collection",
    model: Mileage,
  }).items;

  React.useEffect(()=>{
    let admin = false;
    if(user.signInUserSession.accessToken.payload["cognito:groups"]){
      if (user.signInUserSession.accessToken.payload["cognito:groups"].includes("Admin")){
        admin = true;
      }
    }
    if(!admin){
      navigate("/");
    }
  }, [])

  React.useEffect(() => {
    const dropdownOptions = [{value: "all", label: "All Users"}];
    for (let x in usersDataStore) {
      // remove users with null names from list:
      if (usersDataStore[x].last_name != null && !usersDataStore[x].owner.includes("google")){
        if (usersDataStore[x].owner == null){
          dropdownOptions.push({ value:usersDataStore[x].id, label: usersDataStore[x].first_name + " " + usersDataStore[x].last_name});
        } else {
          dropdownOptions.push({ value:usersDataStore[x].owner, label: usersDataStore[x].first_name + " " + usersDataStore[x].last_name});
        }  
      }
      
    }
    setUserDropdownList(
      <SelectField
        size="small"
        onChange={(e) => {
          if (e.target.value == "all"){
            setSelectedFilterUser(null)
          } else {
            setSelectedFilterUser(e.target.value)
          }
        }}
      >
        {dropdownOptions.map(({value, label}, index) => <option key={value} value={value}>{label}</option>)}
      </SelectField>
    )
  }, [usersDataStore])

  React.useEffect(() => {
    let year = new Date().getFullYear();
    let filterYear = monthFilter.getFullYear();
    setCurrentYearMileage(mileageDataStore.filter(mileage => mileage.year == year)[0])
    setFilteredYearMileage(mileageDataStore.filter(mileage => mileage.year == filterYear)[0])
  }, [mileageDataStore, monthFilter])

  const handleClickOpen = () => {
    setOpen(true);

  };
  const handleClose = () => {
    setOpen(false);
  };

  checkUserTable(user);
    return (
      <div className='App' style={({display: "flex"})} width="100%">
        <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>Update Mileage Rate</DialogTitle>
          <DialogContent>
            <Flex direction="row" alignItems="flex-start">
              <Flex direction="column">
              <CustomMileageCreateForm 
                onSuccess={handleClose}
              />
              </Flex>
            </Flex>
          </DialogContent>
        </Dialog>
        <div className='content' style={({flexGrow:100})}>
          <Header user={user} signOut={signOut}/>
          <Flex className="amplify-flex" direction="row" style={{marginLeft: "1em"}}>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              isDisabled={false}
              variation="primary"
              children="Update mileage"
              onClick={() => {
                handleClickOpen();
              }}
            ></Button>
            <div direction="column">
              <p>{"Current Year ("}{new Date().getFullYear()}{") Mileage: "}{currentYearMileage?.rate}</p>
              <p>{"Filtered Year ("}{monthFilter.getFullYear()}{") Mileage: "}{filteredYearMileage?.rate}</p>
            </div>
          </Flex>
          <Flex className="amplify-flex" direction="column" alignItems="flex-start">
            <div style={{marginLeft: "1vw", marginBottom: "2vh", justifyContent: "left"}}> 
              <div style={{display:"flex", width: "auto", marginLeft: "1em", marginTop: "3px", marginBottom: "3px", justifyContent: "flex-start"}}>
                <p style={{fontWeight: "bold", marginRight: "1vw"}}>Month:</p>
                <DatePicker
                  selected={monthFilter}
                  onChange={(date) => setMonthFilter(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                />
              </div>
              {dropdown(true, "View cars assigned to:", userDropdownList)}
            </div>
            <TotalsHeader overrides={{"TotalsHeader": {width: "100%"}}}/>
            <CustomTotalsTableEntryCollection monthFilter={monthFilter}/>
            <UserCarTotalsEntryCollection userID={user.attributes.sub} monthFilter={monthFilter} selectedFilterUser={selectedFilterUser}></UserCarTotalsEntryCollection>
            <EmployeeEntryCollection/>
          </Flex>
        
        </div>
      </div>
    )
  }
  
  export default AdminView;