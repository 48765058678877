import * as React from "react";
import { CarData, CommuteEntries, Users } from "../models";
import { SortDirection } from "@aws-amplify/datastore";
import {
  getOverrideProps,
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";
import CustomCommuteTableEntry from "./CustomCommuteTableEntry";
import { Collection, Button } from "@aws-amplify/ui-react";
import { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { CommuteTableHeader } from "../ui-components";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { DuplicateCommuteForm } from "./DuplicateCommuteForm";
import { UpdateCommuteForm } from "./UpdateCommuteForm";
import { dropdown } from "../functions/FilterDropdown";
import writeXlsxFile from "write-excel-file";
import Select from 'react-select';



export default function CustomCommuteTableEntryCollection(props) {
  const commuteEntriesPagination = { sort: (s) => s.date(SortDirection.ASCENDING) };
  const commuteEntriesDataStore = useDataStoreBinding({
    type: "collection",
    model: CommuteEntries,
    pagination: commuteEntriesPagination,
  }).items;

  const usersDataStore = useDataStoreBinding({
    type: "collection",
    model: Users,
  }).items;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [filterMonth, setFilterMonth] = useState(null);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState();
  const [selectedCommuteID, setSelectedCommuteID] = useState();
  const [userDropdownList, setUserDropdownList] = useState();
  const [selectedFilterUser, setSelectedFilterUser] = useState();
  const [dropdownValue, setDropdownValue] = useState();

  const { items: itemsProp, userID, adminView, overrideItems, overrides, ...rest } = props;
  const [commuteItems, setCommuteItems] = useState();

  const schema = [
    {
      column: 'Date',
      type: String,
      width: 11,
      defaultValue: commute => commute.date
    },
    {
      column: 'Car',
      type: String,
      defaultValue: commute => commute.carNameString
    },
    {
      column: 'Charge Code',
      type: String,
      width: 13,
      defaultValue: commute => commute.chargeCodeString
    },
    {
      column: 'Start Address',
      type: String,
      width: 40,
      defaultValue: commute => commute.startAddress
    },
    {
      column: 'End Address',
      type: String,
      width: 40,
      defaultValue: commute => commute.endAddress
    },
    {
      column: 'Odometer Start',
      type: Number,
      format: '#,##0',
      width: 15,
      defaultValue: commute => commute.odometerStart
    },
    {
      column: 'Odometer End',
      type: Number,
      format: '#,##0',
      width: 15,
      defaultValue: commute => commute.odometerEnd
    },
    {
      column: 'Miles',
      type: Number,
      format: 'General',
      defaultValue: commute => commute.miles
    },
    {
      column: 'Parking',
      type: Number,
      format: '$#,##0.00',
      defaultValue: commute => commute.parking
    },
    {
      column: 'Tolls',
      type: Number,
      format: '$#,##0.00',
      defaultValue: commute => commute.tolls
    },
    {
      column: 'Total',
      type: Number,
      format: '$#,##0.00',
      defaultValue: commute => commute.total
    }
  ]


  async function createXlsx(objects) {
    if (objects.length > 0) {

      //Calculate info for filename:
      let name = "AllUsers"
      if (selectedFilterUser != null) {
        // get the filtered user's name from the dropdown list:
        name = userDropdownList.props.children.filter(option => option.key === selectedFilterUser)[0].props.children.replace(" ", "");
      }
      // get the month and year from the month filter:
      const month = filterMonth.toLocaleString('en-us', { year: "numeric", month: "numeric" }).replace("/", "-");

      // check for admin, then for filtered user
      if (adminView) {
        // if all users, split objects by commute.usersID, create separate sheets for each
        if (selectedFilterUser == null) {
          //const separated = Object.groupBy(objects, ({usersID}) => usersID);
          var separated = objects.reduce((x, y) => {
            (x[y.usersID] = x[y.usersID] || []).push(y);
            return x;
          }, {});
          const multisheetObjects = Object.values(separated);
          let sheetNames = [];
          let multiSchemas = [];
          for (let user in multisheetObjects) {
            sheetNames.push(userDropdownList.props.children.filter(option => option.key === multisheetObjects[user][0].usersID)[0].props.children)
            multiSchemas.push(schema)
          }
          // write Excel file with multiple sheets:
          await writeXlsxFile(multisheetObjects, {
            schema: multiSchemas,
            sheets: sheetNames,
            fileName: name + "Commutes" + month
          })
          return

        }
      }
      //Write Excel file for single user:
      await writeXlsxFile(objects, {
        schema,
        fileName: name + "Commutes" + month
      })
    }
  }

  React.useEffect(() => {
    const currentMonth = new Date();
    setFilterMonth(currentMonth);
    let startDay = new Date(currentMonth);
    startDay.setUTCDate(1);
    startDay.setUTCHours(0);
    startDay.setUTCMinutes(0);
    startDay.setUTCSeconds(0);
    startDay.setUTCMilliseconds(0);
    let endDay = new Date(startDay);
    endDay.setUTCMonth(endDay.getUTCMonth() + 1);
    endDay.setUTCMilliseconds(endDay.getUTCMilliseconds() - 1);
    setStartDate(startDay);
    setEndDate(endDay);
  }, [])

  React.useEffect(() => {
    const dropdownOptions = [{ value: "all", label: "All Users" }];
    for (let x in usersDataStore) {
      // remove users with null names from list:
      if (usersDataStore[x].last_name != null  && !usersDataStore[x].owner.includes("google")) {
        if (usersDataStore[x].owner == null) {
          dropdownOptions.push({ value: usersDataStore[x].id, label: usersDataStore[x].first_name + " " + usersDataStore[x].last_name });
        } else {
          dropdownOptions.push({ value: usersDataStore[x].owner, label: usersDataStore[x].first_name + " " + usersDataStore[x].last_name });
        }
      }

    }

    if (dropdownValue == null && dropdownOptions.some(item => item.value === userID)) {
      setSelectedFilterUser(userID);
      setDropdownValue(userID);
    }
    setUserDropdownList(
      <Select
        isMulti
        name="user"
        options={dropdownOptions}
        className="basic-multi-select"
        classNamePrefix="select"

        onChange={(e) => {
          if (e.length === 0) {
            setSelectedFilterUser([]);
          } else if (e.some(user => user.value === 'all')) {
            setSelectedFilterUser([]);
          } else {
            setSelectedFilterUser(e);
            setDropdownValue(e);
          }
        }}
      />
    )
  }, [usersDataStore, dropdownValue, userID])

  function commuteCompareFunc(a, b) {
    // sort commutes first by date
    let dateA = a.date;
    let dateB = b.date;
    if (dateA < dateB) { return -1 }
    if (dateA > dateB) { return 1 }
    // if dates are equal, sort by cars, which should keep things organized in Admin View or when a user has multiple cars
    if (a.carNameString && b.carNameString) {
      let carA = a.carNameString.toLowerCase();
      let carB = b.carNameString.toLowerCase();
      if (carA < carB) { return -1 }
      if (carA > carB) { return 1 }
    }
    // if both commutes are for the same day and the same car, sort by starting odometer to ensure they display in chronological order
    let odoA = a.odometerStart;
    let odoB = b.odometerStart;
    if (odoA < odoB) { return -1 };
    if (odoA > odoB) { return 1 };
    //if it gets to this point, just use the order as they appeared in the database
    return 0;
  }

  React.useEffect(() => {
    let filteredCommuteItems = [];

    if (Array.isArray(selectedFilterUser) && selectedFilterUser.length > 0) {
      filteredCommuteItems = selectedFilterUser.reduce((acc, user) => {
        const userItems = commuteEntriesDataStore.filter(item =>
          new Date(item.date) >= startDate &&
          new Date(item.date) < endDate &&
          item.usersID === user.value
        );
        return acc.concat(userItems);
      }, []);
      filteredCommuteItems = Array.from(new Set(filteredCommuteItems));
    } else {
      const allUserIds = usersDataStore.map(entry => entry.id);
      filteredCommuteItems = commuteEntriesDataStore.filter(item =>
        new Date(item.date) >= startDate &&
        new Date(item.date) < endDate &&
        allUserIds.some(userId => item.usersID.includes(userId))
      );
    }
    setCommuteItems(filteredCommuteItems.sort(commuteCompareFunc));
  }, [startDate, endDate, selectedFilterUser, commuteEntriesDataStore, usersDataStore])

  const handleClickOpenDuplicate = (userID, commuteID) => {
    setSelectedUserID(userID);
    setSelectedCommuteID(commuteID);
    setOpenDuplicate(true);

  };
  const handleClickOpenEdit = (userID, commuteID) => {
    setSelectedUserID(userID);
    setSelectedCommuteID(commuteID);
    setOpenEdit(true);

  };

  const handleClose = () => {
    setOpenDuplicate(false);
    setOpenEdit(false);
  };

  return (
    <div>
      <div style={{ display: "flex", direction: "row", alignItems: "center", marginBottom: "2vh" }}>
        <div style={{ display: "flex", width: "auto", marginLeft: "1em", justifyContent: "flex-start", alignItems: "center" }}>
          <div style={{ fontWeight: "bold", whiteSpace: "nowrap", marginRight: "1vw" }}>Select Month:</div>
          <DatePicker
            selected={filterMonth}
            onChange={(date) => {
              let start = new Date(date);
              start.setUTCHours(0, 0, 0);
              start.setUTCDate(1);
              let end = new Date(start);
              end.setUTCMonth(end.getUTCMonth() + 1);
              end.setUTCSeconds(end.getUTCSeconds() - 1);
              setStartDate(start);
              setEndDate(end);
              setFilterMonth(date);
            }}
            dateFormat="MM/yyyy"
            showMonthYearPicker
          />
          {/* <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          /> */}
        </div>
        {dropdown(adminView, "Select User:", userDropdownList)}
        <div style={{ display: "flex", width: "auto", marginLeft: "1em", justifyContent: "flex-start", alignItems: "center" }}>
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="small"
            isDisabled={(commuteItems === undefined) ? false : commuteItems.length < 1}
            variation="primary"
            children="Download as XLSX"
            onClick={() => {
              createXlsx(commuteItems);
            }}
          ></Button>
        </div>
      </div>



      <Dialog open={openDuplicate} onClose={handleClose} maxWidth="lg">
        <DialogTitle>Duplicate Commute Entry</DialogTitle>
        <DialogContent>
          <DuplicateCommuteForm userID={userID} commuteid={selectedCommuteID} closeDialog={handleClose}></DuplicateCommuteForm>
        </DialogContent>
      </Dialog>
      <Dialog open={openEdit} onClose={handleClose} maxWidth="lg">
        <DialogTitle>Edit Commute Entry</DialogTitle>
        <DialogContent>
          <UpdateCommuteForm userID={selectedUserID} commuteid={selectedCommuteID} closeDialog={handleClose}></UpdateCommuteForm>
        </DialogContent>
      </Dialog>
      <CommuteTableHeader></CommuteTableHeader>
      <Collection
        type="list"
        isPaginated={true}
        searchPlaceholder="Search..."
        itemsPerPage={10}
        direction="column"
        alignItems="stretch"
        justifyContent="left"
        items={commuteItems || []}
        {...rest}
        {...getOverrideProps(overrides, "TableEntryCollection")}
      >
        {(item, index) => (
          <CustomCommuteTableEntry
            commuteEntries={item}
            key={item.id}
            openDuplicateDialog={handleClickOpenDuplicate}
            openEditDialog={handleClickOpenEdit}
            {...(overrideItems && overrideItems({ item, index }))}
          ></CustomCommuteTableEntry>
        )}
      </Collection>


    </div>
  );
}
