import { DataStore } from "aws-amplify";
import { Users } from "../models";

export async function checkUserTable(user){

    const subscription = DataStore.observeQuery(Users, c => c.email.eq(user.attributes.email)).subscribe(snapshot => {
        const { items, isSynced } = snapshot;
        if (items.length < 1 && isSynced){
            DataStore.save(
                new Users({
                    id: user.username,
                    first_name: user.attributes['given_name'],
                    last_name: user.attributes['family_name'],
                    email: user.attributes.email
              
                })
            );
        }
    });
  }
  