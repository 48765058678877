import * as React from "react";
import { Expense, Users } from "../models";
import { SortDirection } from "@aws-amplify/datastore";
import {
  getOverrideProps,
  useDataStoreBinding,
} from "@aws-amplify/ui-react/internal";
import CustomExpenseTableEntry from "./CustomExpenseTableEntry";
import { Collection, SelectField } from "@aws-amplify/ui-react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { UpdateExpenseForm } from "./UpdateExpenseForm";
import { ExpenseTableHeader } from "../ui-components";
import { dropdown } from "../functions/FilterDropdown";
import { ConstructionOutlined } from "@mui/icons-material";

export default function CustomExpenseTableEntryCollection(props) {
    const { items: itemsProp, adminView, overrideItems, overrides, ...rest } = props;
    const itemsPagination = { sort: (s) => s.Date(SortDirection.ASCENDING) };
    const [items, setItems] = React.useState(undefined);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [selectedUserID, setSelectedUserID] = React.useState();
    const [selectedExpenseID, setselectedExpenseID] = React.useState();
    const [userDropdownList, setUserDropdownList] = React.useState();
    const [selectedFilterUser, setSelectedFilterUser] = React.useState();
    const itemsDataStore = useDataStoreBinding({
        type: "collection",
        model: Expense,
        pagination: itemsPagination,
      }).items;
    const usersDataStore = useDataStoreBinding({
      type: "collection",
      model: Users,
    }).items;
    React.useEffect(() => {
      if (itemsProp !== undefined) {
        setItems(itemsProp);
        return;
      }
      async function setItemsFromDataStore() {
        var loaded = await Promise.all(
          itemsDataStore.map(async (item) => ({
            ...item
          }))
        );
        if (selectedFilterUser) {
          setItems(loaded.filter(item => item.usersID.includes(selectedFilterUser)));
        } else {
          // filter by all users
          let allUserIds = usersDataStore.map( entry => entry.id);
          setItems(loaded.filter(item => allUserIds.some( userId => item.usersID.includes(userId))))
        }
        
      }
      setItemsFromDataStore();
    }, [itemsProp, itemsDataStore, selectedFilterUser]);
    
      React.useEffect(() => {
        const dropdownOptions = [{value: "all", label: "All Users"}];
        for (let x in usersDataStore) {
          // remove users with null names from list:
          if (usersDataStore[x].last_name != null && usersDataStore[x].owner && !usersDataStore[x].owner.includes("google")){
            if (usersDataStore[x].owner == null){
              dropdownOptions.push({ value:usersDataStore[x].id, label: usersDataStore[x].first_name + " " + usersDataStore[x].last_name});
            } else {
              dropdownOptions.push({ value:usersDataStore[x].owner, label: usersDataStore[x].first_name + " " + usersDataStore[x].last_name});
            }  
          }
          
        }
        setUserDropdownList(
          <SelectField
            size="small"
            onChange={(e) => {
              if (e.target.value === "all"){
                setSelectedFilterUser(null)
              } else {
                setSelectedFilterUser(e.target.value)
              }
            }}
          >
            {dropdownOptions.map(({value, label}, index) => <option key={value} value={value}>{label}</option>)}
          </SelectField>
        )
      }, [usersDataStore])
    
      const handleClickOpenEdit = (userID, expenseID) => {
        console.log("Expense ID:", expenseID)
        console.log("Dialog open")
        setSelectedUserID(userID);
        setselectedExpenseID(expenseID);
        setOpenEdit(true);
    
      };
    
      const handleClose = () => {
        setOpenEdit(false);
      };
      return (
        <div>
    
          {dropdown(adminView, "Select User:", userDropdownList)}
          
          <ExpenseTableHeader/>
          <Dialog open={openEdit} onClose={handleClose}>
            <DialogTitle>Edit Expense Entry</DialogTitle>
            <DialogContent>
              <UpdateExpenseForm userID={selectedUserID} expenseid={selectedExpenseID} closeDialog={handleClose}></UpdateExpenseForm>
            </DialogContent>
          </Dialog>
          <Collection
          type="list"
          isPaginated={true}
          searchPlaceholder="Search..."
          direction="column"
          justifyContent="left"
          items={items || []}
          {...getOverrideProps(overrides, "ExpenseTableEntryCollection")}
          {...rest}
        >
          {(item, index) => (
            <CustomExpenseTableEntry
              expenseData={item}
              key={item.id}
              openEditDialog={handleClickOpenEdit}
              {...(overrideItems && overrideItems({ item, index }))}
            ></CustomExpenseTableEntry>
          )}
        </Collection>
        </div>
        
      );
    }
